<template>
  <main>
    <div class="pb">
      <Button icon="md-return-left" shape="circle" @click="$router.back()">返回</Button>
    </div>

    <Form ref="form" :model="form" :rules="validate" label-position="top">
      <Divider orientation="left">基本信息</Divider>
      <Row>
        <Col :span="9" :offset="3">
          <FormItem label="标题" prop="title">
            <Input :maxlength="45" show-word-limit v-model="form.title"/>
          </FormItem>
          <FormItem label="主图" prop="cover">
            <upload-image class="w50-max" name="news" v-model="form.cover"/>
          </FormItem>
          <FormItem label="日期">
            <DatePicker
              type="date"
              :value="form.date"
              placeholder="请选择资讯产生日期"
              @on-change="filterDate"
            ></DatePicker>
          </FormItem>
          <FormItem label="权重排序">
            <InputNumber
              :min="-32768"
              :max="32767"
              class="input-number-long"
              v-model="form.weight"
            />
            <p class="text-sm text-grey">从大到小排序</p>
          </FormItem>

          <FormItem label="是否可见">
            <i-switch v-model="form.visible" size="large" :true-value="1" :false-value="0">
              <span slot="open">是</span>
              <span slot="close">否</span>
            </i-switch>
          </FormItem>
          <FormItem label="内容详情">
            <editor name="news" v-model="form.details"/>
          </FormItem>
        </Col>
      </Row>

      <Divider/>
      <Row>
        <Col :span="9" :offset="3">
          <FormItem>
            <Button type="primary" icon="md-add-circle" @click="submit" v-if="!id">确认添加</Button>
            <Button type="success" icon="md-filing" @click="submit" v-else>保存修改</Button>
          </FormItem>
        </Col>
      </Row>
    </Form>
  </main>
</template>

<script>
import UploadImage from "@/components/UploadImage";
import Editor from "@/components/Editor";
export default {
  components: {
    UploadImage,
    Editor
  },
  data() {
    return {
      id: this.$route.params.id,
      form: {
        title: "",
        cover: "",
        details: "",
        date: "",
        weight: 0,
        visible: 1
      },
      validate: {
        title: [{ required: true, message: "请输入标题", trigger: "blur" }],
        cover: [{ required: true, message: "请上传主图", trigger: "blur" }],
        details: [{ required: true, message: "请输入内容", trigger: "blur" }],
        date: [
          { required: true, message: "请选择资讯产生日期", trigger: "blur" }
        ]
      }
    };
  },
  created() {
    this.id && this.loadData();
  },
  methods: {
    submit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          const url = "/news/" + (this.id ? "update/id/" + this.id : "create");
          this.$http.post(url, this.form).then(res => {
            this.$router.back();
          });
        }
      });
    },
    loadData() {
      this.$http.get("/news/detail/id/" + this.id).then(res => {
        this.form = res;
      });
    },
    filterDate(e) {
      this.form.date = e;
    }
  }
};
</script>